"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.displayNotification = exports.initialiseNotifications = void 0;
var secure_trading_wrapper_1 = require("../secure-trading-wrapper");
require("./notifications.scss");
function initialiseNotifications(appElement) {
    var container = document.createElement("div");
    container.className = "notifications-container";
    appElement.appendChild(container);
    (0, secure_trading_wrapper_1.registerStConfigurator)(function (st) {
        st.on("paymentCompleted", function () {
            displayNotification(container, NotificationType.PAYMENT_SUCCESS);
        });
        st.on("paymentFailed", function () {
            displayNotification(container, NotificationType.PAYMENT_ERROR);
        });
        st.on("paymentCanceled", function () {
            displayNotification(container, NotificationType.PAYMENT_ERROR);
        });
    });
    // PPRO / A2A notifications
    var url = new URL(window.location.href);
    var settleStatus = url.searchParams.get("settlestatus");
    if (["0", "1", "10", "100"].includes(settleStatus)) {
        displayNotification(container, NotificationType.PAYMENT_SUCCESS);
    }
    if (["2", "3"].includes(settleStatus)) {
        displayNotification(container, NotificationType.PAYMENT_ERROR);
    }
}
exports.initialiseNotifications = initialiseNotifications;
function displayNotification(container, type) {
    container.style.display = "flex";
    var notification = document.createElement("div");
    notification.classList.add("notification");
    switch (type) {
        case NotificationType.PAYMENT_SUCCESS: {
            notification.appendChild(document.createTextNode("Payment successful"));
            notification.classList.add("notification__success");
            break;
        }
        case NotificationType.PAYMENT_ERROR: {
            notification.appendChild(document.createTextNode("Payment failure"));
            notification.classList.add("notification__failure");
            break;
        }
        default: {
            throw new Error("NotificationType ".concat(type, " not registered"));
        }
    }
    container.appendChild(notification);
    // The notification will disappear after a set time
    setTimeout(function () {
        notification.remove();
        container.style.display = "none";
    }, 4000);
}
exports.displayNotification = displayNotification;
var NotificationType;
(function (NotificationType) {
    NotificationType[NotificationType["PAYMENT_SUCCESS"] = 0] = "PAYMENT_SUCCESS";
    NotificationType[NotificationType["PAYMENT_ERROR"] = 1] = "PAYMENT_ERROR";
})(NotificationType || (NotificationType = {}));
