"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerStConfigurator = exports.SecureTrading = void 0;
function SecureTrading(config) {
    var st = window.SecureTrading(config);
    for (var _i = 0, registeredConfigurators_1 = registeredConfigurators; _i < registeredConfigurators_1.length; _i++) {
        var configurator = registeredConfigurators_1[_i];
        configurator(st);
    }
    return st;
}
exports.SecureTrading = SecureTrading;
var registeredConfigurators = [];
function registerStConfigurator(configurator) {
    registeredConfigurators.push(configurator);
}
exports.registerStConfigurator = registerStConfigurator;
